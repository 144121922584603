<template>
  <div class="tw-flex tw-flex-col tw-gap-3">
    <div class="tw-flex tw-items-center">
      <div class="tw-grow-0 tw-pr-4 tw-text-3xl tw-font-bold tw-tracking-wide">
        Football Macro
      </div>

      <div class="tw-grow tw-h-1 tw-bg-black" />
    </div>

    <div class="tw-grid tw-grid-cols-4 tw-divide-x tw-divide-black">
      <div v-for="(topic, index) in topics" :key="index" class="tw-p-4 tw-flex tw-flex-col tw-gap-1">
        <div class="tw-text-xl tw-font-bold tw-leading-6">
          {{ topic.title }}
        </div>
      </div>
    </div>

    <div class="tw-w-full tw-h-1 tw-bg-black" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      topics: [
        {
          title: 'Football in general is up 10%, where as sports in general are down 2%'
        },
        {
          title: 'Football is most popular in europe. And in the United Kingdom'
        },
        {
          title: 'Football is the sport with the highest Trust Value of all'
        },
        {
          title: 'Football is the second most talked about sport world wide'
        }
      ]
    }
  }
}
</script>
